<template>
    <div>
        <b-tabs v-model="tabIndex">
            <b-tab
                active
            >
                <template #title>
                    <!-- <feather-icon icon="HomeIcon" /> -->
                    <span v-if="selections.length > 0">{{ selections[0].label }}</span>
                </template>

                <b-card-text>
                </b-card-text>
            </b-tab>
            <b-tab
                v-for="child in children"
                :key="child.id"
            >
                <template #title>
                    <!-- <feather-icon icon="HomeIcon" /> -->
                    <span>{{ child.name }}</span>
                </template>

                <b-card-text>
                </b-card-text>
            </b-tab>
        </b-tabs>

        <!-- Form -->
        <b-row>
            <b-col cols="12">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="">
                        <b-row>
                            <b-col v-if="tabIndex > 0" cols="12" md="2">
                                <b-form-group v-if="tabIndex > 0" labe-for="location_id" :label="selections[tabIndex - 1].label">
                                    <validation-provider #default="{ errors }" :name="selections[tabIndex - 1].label" rules="">
                                        <b-form-select
                                            size="sm"
                                            id="location_id"
                                            name="location_id"
                                            v-model="location.location_id"
                                            @change="changeLocationFilter"
                                        >
                                            <b-form-select-option :value="null">Filter...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="location in selections[tabIndex - 1].locations"
                                                :key="location.id"
                                                :value="location.id"
                                            >
                                                {{ location.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="1">
                                <b-form-group labe-for="code" :label="'Code'">
                                    <validation-provider #default="{ errors }" :name="'Code'" rules="">
                                        <b-form-input
                                            id="code"
                                            name="code"
                                            size="sm"
                                            :placeholder="'Code'"
                                            :state="errors.length > 0 ? false : null"
                                            auto-focus
                                            v-model="location.code"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.code">{{ serverErrors.code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="2">
                                <b-form-group labe-for="name" :label="'Name'">
                                    <validation-provider #default="{ errors }" :name="'Name'" rules="">
                                        <b-form-input
                                            id="name"
                                            name="name"
                                            size="sm"
                                            :placeholder="'Name'"
                                            :state="errors.length > 0 ? false : null"
                                            auto-focus
                                            v-model="location.name"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="7">
                                <b-row>
                                    <b-col cols="2" style="padding-top:28px; !important;">
                                        <b-button
                                            size="sm"
                                            variant="primary"
                                            @click="submit"
                                        >
                                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                            <span v-if="!saving">Save</span>
                                            <span v-if="saving">Saving...</span>
                                        </b-button>
                                    </b-col>
                                    <b-col cols="10" style="padding-top:28px; !important;">
                                        <b-button
                                            size="sm"
                                            variant="outline-secondary"
                                            @click="resetForm"
                                        >
                                            Clear
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <!-- {{ location }} -->
                    </b-form>
                </validation-observer>
            </b-col>
        </b-row>
        <!-- ./Form -->

        <!-- Table Container Card -->
        <!-- <b-card no-body class="mb-0"> -->
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search -->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
                v-if="!loading"
            >
                <template #cell(code)="data">
                    <span v-if="data.item.code">{{ data.item.code.toUpperCase() }}</span>
                </template>

                <template #cell(name)="data">
                    <span v-if="data.item.name">{{ data.item.name.toUpperCase() }}</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>
        
                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
            

        <!-- </b-card> -->
        <!-- ./Table Container Card -->
        <div v-if="loading">
      <p>Loading data...</p>
      <div class="spinner"></div>
    </div>
    </div>
    
</template>

<script>
    import {
        BTabs, BTab, BCardText,
        BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner,
        BRow, BCol, BCard, BTable, BDropdown, BDropdownItem, BPagination,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { required } from '@validations'
    import store from '@/store'
    import { ref, onUnmounted, onMounted, watch } from '@vue/composition-api'
    import locationsStoreModule from '@/views/cromis/locations/locationsStoreModule'
    import useLocationsList from '@/views/cromis/locations/useLocationsList'
    export default {
        props: {},
        components: {
            BCardText, BTabs, BTab,
            BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner, vSelect,
            BRow, BCol, BCard, BTable, BDropdown, BDropdownItem, BPagination,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            const saving = ref(null)
            const serverErrors = ref(null)
            const dataForm = ref(null)
            const selections = ref([])
            const levels = ref(null)
            const children = ref([])
            const tabIndex = ref(0)
            const prevTab = ref(0)
            const loading = ref(false)

            const location = ref({
                id: null,
                location_id: null,
                code: null,
                name: null,
                level_id: null,
                loading: true,
            })

            const CROMIS_STORE_MODULE_NAME = 'cromis-location'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, locationsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-location/levels')
                        .then(response => {
                            levels.value = response.data.levels[0]

                            selections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            location.value.level_id = levels.value.id

                            let list = levels.value.children

                            while(list.length > 0){
                                children.value.push(list[0])
                                if(list[0].children > 0){
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                }
                                else{
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                }

                                list = list[0].children
                            }
                        })
                        .catch(error => {
                            console.log('got levels error', error)
                        })

                await store.dispatch('cromis-location/list')
                            .then(response => {
                                populateSelections(response.data.locations)
                                loading.value = false
                            })
                            .catch(error => {
                                console.log('got locations error error', error)
                            })
            })

            const populateSelections = (locations) => {
                for(let i = 0; i < locations.length; i++){
                    for(let x = 0; x < selections.value.length; x++){
                        if(locations[i].level.name.toLowerCase() === selections.value[x].level){
                            selections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }

            const changeLocationFilter = () => {
                location_id.value = location.value.location_id
            }

            watch([tabIndex, location], () => {
                if(tabIndex.value === 0){
                    level_id.value = levels.value.id
                    location.value.level_id = levels.value.id
                }
                else{
                    level_id.value = children.value[tabIndex.value - 1].id
                    location.value.level_id = children.value[tabIndex.value - 1].id
                }

                if(prevTab.value !== tabIndex.value){
                    location.value = {
                        id: null,
                        location_id: null,
                        code: null,
                        name: null,
                        level_id: null,
                        loading: true,
                    }
                    prevTab.value = tabIndex.value
                }
            })

            // Form Methods
            const invokeUpdateForm = (item) => {
                let id = null
                selections.value[tabIndex.value].locations.map((foundLocation) => {
                    if(foundLocation.id === item.id){
                        id = foundLocation.parent_id
                    }
                })

                location.value = {
                    id: item.id,
                    location_id: id,
                    code: item.code,
                    name: item.name,
                    level_id: level_id.value,
                }
            }

            const resetForm = () => {
                location.value = {
                    id: null,
                    location_id: null,
                    code: null,
                    name: null,
                    level_id: level_id.value,
                }
            }

            const isFormValid = async () => {
                let isValid = false 
                await dataForm.value.validate().then(success => {
                    isValid = success
                })

                return isValid
            }

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()
                serverErrors.value = null

                // Handle form submit
                if (location.value.id === null || location.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(location.value)
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-location/create', location.value)
                    .then(response => {
                        refetch()
                        saving.value = false
                        resetForm()

                        context.root.$swal({
                            icon: 'success',
                            text: `${selections.value[tabIndex.value].label} ${response.data.name} has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        saving.value = false
                        resetForm()
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-location/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to ${selections.value[tabIndex.value].label} ${response.data.name} has been made successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                        resetForm()
                    })
                    .catch(error => {
                        saving.value = false
                        resetForm()
                        console.log(error)
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-location/remove', id)
                            .then(response => {
                                refetch()

                                context.root.$swal({
                                    icon: 'info',
                                    title: 'System Info',
                                    text: 'Location was deleted successfully',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-info',
                                    },
                                    buttonsStyling: true,
                                })
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: false,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            })
            }

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
                level_id,
                location_id,
            } = useLocationsList()

            return {
                saving,
                serverErrors,
                dataForm,
                records,
                fetch,
                refetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                level_id,
                location_id,
                changeLocationFilter,
                resetForm,
                invokeUpdateForm,
                isFormValid,
                submit,
                handleCreate,
                handleUpdate,
                remove,
                
                selections,
                levels,
                children,
                tabIndex,
                location,
                loading,
                required,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>